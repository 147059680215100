$(document).foundation();


		



		// no js
		$('body').removeClass('no-js');
		// END no js





		// GOOGLE ANALYTICS
		(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
		(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
		m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
		})(window,document,'script','//www.google-analytics.com/analytics.js','ga');

		ga('create', 'UA-28850853-1', 'julien-watelet.be');
		ga('send', 'pageview');
		 // END GOOGLE ANALYTICS

		// update your browser
		var $buoop = {};
		$buoop.ol = window.onload;
		window.onload=function(){
		 try {if ($buoop.ol) $buoop.ol();}catch (e) {}
		 var e = document.createElement("script");
		 e.setAttribute("type", "text/javascript");
		 e.setAttribute("src", "//browser-update.org/update.js");
		 document.body.appendChild(e);
		}
		// end update your browser

		// SCROLL SMOOTH
		$('a[href^="#"]').click(function(){
		    var the_id = $(this).attr("href");

		    $('html, body').animate({
		        scrollTop:$(the_id).offset().top
		    }, 'slow');
		    return false;
		});
		// END SCROLL SMOOTH

		// waypoint et IMG FIXE SCROLL  
		$(document).scroll(function(){
			var top = $(document).scrollTop();
			var widowsHeight = $(window).height();
			var offset = $( '#travaux' ).offset();
			var offsetTop = offset.top;
			if (top < (offsetTop - widowsHeight)) {
				$('#element-moi').removeClass('no-fixed');
			}
			else{

				$('#element-moi').addClass('no-fixed');
			}
		});

		// END waypoint et img fixe scroll



		// intro 100%

		var $window = $(window);

		if($window.width() > 800){
			var heightwindow = $(window).height();
			$('#intro').css('height', heightwindow);

			$(window).resize(function(){
			  var heightwindow = $(window).height();
			 });
		};
		// END intro 100%





	   // Hide Header on on scroll down
			var didScroll;
			var lastScrollTop = 0;
			var delta = 5;
			var navbarHeight = $('header').outerHeight();

			$(window).scroll(function(event){
			    didScroll = true;

			});

			setInterval(function() {
			    if (didScroll) {
			        hasScrolled();
			        didScroll = false;
			    }
			}, 250);

			function hasScrolled() {
				
			    var st = $(this).scrollTop();
			    

			    if(Math.abs(lastScrollTop - st) <= delta)
			        return;
			    
			    if (st > lastScrollTop && st > navbarHeight){

			        $('header').removeClass('nav-down').addClass('hideheader');
			    } else {

			        if(st + $(window).height() < $(document).height()) {
			            $('header').removeClass('hideheader').addClass('nav-down');
			        }
			    }
			    
			    lastScrollTop = st;
			}

			// END Hide Header on on scroll down






		//END navigation au scroll (hidden)


		// MENU ACTIVE ON SCROLL WITH WAYPOINT =======================================

		$('#bouton-home').waypoint(function(direction) {
			if ( direction == "down") {
				$('.active2').addClass('active');
				$('.active1').removeClass('active');
			}
		});

		$('#bouton-home').waypoint(function(direction) {
			if ( direction == "up") {
				$('.active1').addClass('active');
				$('.active2').removeClass('active');
			}
		});

		$('#bouton-moi').waypoint(function(direction) {
			if ( direction == "down") {
				$('.active3').addClass('active');
				$('.active2').removeClass('active');
			} else {

			}
		});

		$('#bouton-moi').waypoint(function(direction) {
		  if ( direction == "up") {
		    $('.active2').addClass('active');
		    $('.active3').removeClass('active');
		  } else {

		  }
		});

		$('#contact').waypoint(function(direction) {
			if ( direction == "down") {
				$('.active4').addClass('active');
				$('.active3').removeClass('active');
			} else {

			}
		});

		$('#contact').waypoint(function(direction) {
			if ( direction == "up") {
				$('.active3').addClass('active');
				$('.active4').removeClass('active');
			} else {

			}
		});


		// END MENU ACTIVE ON SCROLL WITH WAYPOINT =======================================


		/**
		 * FloatLabels
		 * URL: http://clubdesign.github.io/floatlabels.js/
		 * Author: Marcus Pohorely ( http://www.clubdesign.at )
		 * Copyright: Copyright 2013 / 2014 http://www.clubdesign.at
		 *
		 * Adapted for bootstrap projects by Michael Levin 2/20/14
		 */
		 
		;
		(function ($, window, document, undefined) {
		    var pluginName = "floatlabel",
		        defaults = {
		            slideInput: true,
		            labelStartTop: '0px',
		            labelEndTop: '7px',
		            paddingOffset: '12px',
		            transitionDuration: 0.1,
		            transitionEasing: 'ease-in-out',
		            labelClass: '',
		            typeMatches: /text|password|email|number|search|url/
		        };

		    function Plugin(element, options) {
		        this.$element = $(element);
		        this.settings = $.extend({}, defaults, options);
		        this.init();
		    }
		    Plugin.prototype = {
		        init: function () {
		            var self = this,
		                settings = this.settings,
		                transDuration = settings.transitionDuration,
		                transEasing = settings.transitionEasing,
		                thisElement = this.$element;
		            var animationCss = {
		                '-webkit-transition': 'all ' + transDuration + 's ' + transEasing,
		                '-moz-transition': 'all ' + transDuration + 's ' + transEasing,
		                '-o-transition': 'all ' + transDuration + 's ' + transEasing,
		                '-ms-transition': 'all ' + transDuration + 's ' + transEasing,
		                'transition': 'all ' + transDuration + 's ' + transEasing
		            };
		            if (thisElement.prop('tagName').toUpperCase() !== 'INPUT') {
		                return;
		            }
		            if (!settings.typeMatches.test(thisElement.attr('type'))) {
		                return;
		            }
		            var elementID = thisElement.attr('id');
		            if (!elementID) {
		                elementID = Math.floor(Math.random() * 100) + 1;
		                thisElement.attr('id', elementID);
		            }
		            var placeholderText = thisElement.attr('placeholder');
		            var floatingText = thisElement.data('label');
		            var extraClasses = thisElement.data('class');
		            if (!extraClasses) {
		                extraClasses = '';
		            }
		            if (!placeholderText || placeholderText === '') {
		                placeholderText = "Vous avez oublié d'ajouter l'attribut dans l'espace réservé à cet effet!";
		            }
		            if (!floatingText || floatingText === '') {
		                floatingText = placeholderText;
		            }
		            this.inputPaddingTop = parseFloat(thisElement.css('padding-top')) + parseFloat(settings.paddingOffset);
		            thisElement.wrap('<div class="floatlabel-wrapper" style="position:relative"></div>');
		            thisElement.before('<label for="' + elementID + '" class="label-floatlabel ' + settings.labelClass + ' ' + extraClasses + '">' + floatingText + '</label>');
		            this.$label = thisElement.prev('label');
		            this.$label.css({
		                'position': 'absolute',
		                'top': settings.labelStartTop,
		                'left': '8px', //thisElement.css('padding-left'),
		                'display': 'none',
		                '-moz-opacity': '0',
		                '-khtml-opacity': '0',
		                '-webkit-opacity': '0',
		                'opacity': '0',
		                'font-size': '11px',
		                'font-weight': 'bold',
		                'color': '#838780'
		            });
		            if (!settings.slideInput) {
		                thisElement.css({
		                    'padding-top': this.inputPaddingTop
		                });
		            }
		            thisElement.on('keyup blur change', function (e) {
		                self.checkValue(e);
		            });
		            thisElement.on('blur', function () {
		                thisElement.prev('label').css({
		                    'color': '#838780'
		                });
		            });
		            thisElement.on('focus', function () {
		                thisElement.prev('label').css({
		                    'color': '#A67F4E'
		                });
		            });
		            window.setTimeout(function () {
		                self.$label.css(animationCss);
		                self.$element.css(animationCss);
		            }, 100);
		            this.checkValue();
		        },
		        checkValue: function (e) {
		            if (e) {
		                var keyCode = e.keyCode || e.which;
		                if (keyCode === 9) {
		                    return;
		                }
		            }
		            var thisElement = this.$element,
		                currentFlout = thisElement.data('flout');
		            if (thisElement.val() !== "") {
		                thisElement.data('flout', '1');
		            }
		            if (thisElement.val() === "") {
		                thisElement.data('flout', '0');
		            }
		            if (thisElement.data('flout') === '1' && currentFlout !== '1') {
		                this.showLabel();
		            }
		            if (thisElement.data('flout') === '0' && currentFlout !== '0') {
		                this.hideLabel();
		            }
		        },
		        showLabel: function () {
		            var self = this;
		            self.$label.css({
		                'display': 'block'
		            });
		            window.setTimeout(function () {
		                self.$label.css({
		                    'top': self.settings.labelEndTop,
		                    '-moz-opacity': '1',
		                    '-khtml-opacity': '1',
		                    '-webkit-opacity': '1',
		                    'opacity': '1'
		                });
		                if (self.settings.slideInput) {
		                    self.$element.css({
		                        'padding-top': self.inputPaddingTop
		                    });
		                }
		                self.$element.addClass('active-floatlabel');
		            }, 50);
		        },
		        hideLabel: function () {
		            var self = this;
		            self.$label.css({
		                'top': self.settings.labelStartTop,
		                '-moz-opacity': '0',
		                '-khtml-opacity': '0',
		                '-webkit-opacity': '0',
		                'opacity': '0'
		            });
		            if (self.settings.slideInput) {
		                self.$element.css({
		                    'padding-top': parseFloat(self.inputPaddingTop) - parseFloat(this.settings.paddingOffset)
		                });
		            }
		            self.$element.removeClass('active-floatlabel');
		            window.setTimeout(function () {
		                self.$label.css({
		                    'display': 'none'
		                });
		            }, self.settings.transitionDuration * 1000);
		        }
		    };
		    $.fn[pluginName] = function (options) {
		        return this.each(function () {
		            if (!$.data(this, "plugin_" + pluginName)) {
		                $.data(this, "plugin_" + pluginName, new Plugin(this, options));
		            }
		        });
		    };
		})(jQuery, window, document);

		// END Floatlabels


		// floatlabel pour le formulaire
		$('.floatlabel_form input').floatlabel();
        	$('tex.floatlabel_form tarea').floatlabel();
        // END floatlabel pour le formulaire




		// Form validation
		// $(function() {
		// 	$("#button").click(function(){
		// 		valid=true;
		// 		if($("#nom").val().length < 1) {
		// 			$(".errors-display p.no-name").fadeIn();
		// 			valid = false;
		// 		}
		// 		else {
		// 			$(".errors-display p.no-name").css("display", "none");
		// 		}


		// 		if ($("#adresse").val().length < 1 ) {
		// 			$(".errors-display p.no-address").fadeIn();
		// 			valid = false;
		// 		}
		// 		else if (!$("#adresse").val().match(/^[a-zA-Z0-9._-]+@[a-z0-9._-]{2,}\.[a-z]{2,4}$/)) {

		// 			$(".errors-display p.no-address").css("display", "none");
		// 			$(".errors-display p.no-valid-address").fadeIn();
		// 			valid = false;
		// 		}
		// 		else {
		// 			$(".errors-display p.no-address").css("display", "none");
		// 		}


		// 		if($("#message").val().length < 1) {
		// 			$(".errors-display p.no-msg").fadeIn();
		// 			valid = false;
		// 		}
		// 		else {
		// 			$(".errors-display p.no-msg").css("display", "none");
		// 		}


		// 		return valid;
		// 	});
		// });
		// END Form validation



/*burger*/
$('.material--burger').on('click', function() {
        $(this).toggleClass('material--arrow');
});




$(".navigation--button, .material--burger").click(function() {
        $(".menu").toggleClass("menu_visible");
        return false;
    });
/*burger*/



function initialize() {


		var styles = [
					  {
					    "elementType": "geometry.fill",
					    "stylers": [
					      { "hue": "#ffa200" },
					      { "lightness": 19 }
					    ]
					  },{
					    "featureType": "poi",
					    "stylers": [
					      { "visibility": "off" }
					    ]
					  },{
					    "featureType": "road",
					    "elementType": "labels.icon",
					    "stylers": [
					      { "visibility": "off" }
					    ]
					  }
					];



	  	var styledMap = new google.maps.StyledMapType(styles, {name: "Gmap stylée"});

	    var mapOptions = {
	    	
	      center: new google.maps.LatLng(49.682954, 5.813106),
	      zoom: 15,
	      disableDefaultUI: true,
	      zoomControl: true,
	      scrollwheel: false,
	      mapTypeId: google.maps.MapTypeId.ROADMAP
	    };
	    var map = new google.maps.Map(document.getElementById("map-canvas"),
	        mapOptions);
	    map.mapTypes.set('map_style', styledMap);
        map.setMapTypeId('map_style');

        var image = 'squelettes/dist/assets/img/logo.png';
  		var myLatLng = new google.maps.LatLng(49.682954, 5.813106);
  		var jwMarker = new google.maps.Marker({
	      	position: myLatLng,
	      	map: map,
	      	icon: image
      		});

  		google.maps.event.addListener(jwMarker, 'click', function() {
		    window.open('https://plus.google.com/u/0/+JulienWatelet/about');
		  });

	  }
	  
	  google.maps.event.addDomListener(window, 'load', initialize);